<template>
  <div>
    <a-row class="left">
        <a-col class="title">关于个体工商户代开发票限额的通知</a-col>
        <a-col class="date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
        <a-divider class="main"></a-divider>
        <div class="content">
            <p>一、根据《国家税务总局关于统一小规模纳税人标准等若干增值税问题的公告》（国家税务总局公告2018年第18号）规定：“八、自转登记日的下期起连续不超过12个月或者连续不超过4个季度的经营期内，转登记纳税人应税销售额超过财政部、国家税务总局规定的小规模纳税人标准的，应当按照《增值税一般纳税人登记管理办法》（国家税务总局令第43号）的有关规定，向主管税务机关办理一般纳税人登记。”</p>
            <br/>
            <p>二、根据《财政部税务总局关于统一增值税小规模纳税人标准的通知》（财税〔2018〕33号）规定：“一、增值税小规模纳税人标准为年应征增值税销售额500万元及以下。”</p>
            <br>
            <p>“中再物贸云”税票平台以上述条款为依据，对平台从事交易业务的个体工商户用户交易上限设定为不含增值税销售额500万元，计算期限设置为代开发票时间点连续滚动12个月。</p>
            <p><b>望各位用户根据自身业务情况安排平台交易，特此通知！</b></p>
        </div>

        <a-row :gutter="20">
            <a-col :span="12">
                <div class="next" @click="$router.push('/notice-details/s3')">
                    <div class="align">
                        <p class="to">上一篇</p>
                        <p class="to-title">中再物贸云交易平台执行“实名认证”入驻</p>
                    </div>
                </div>
            </a-col>
            <a-col :span="12">
                <div class="next" @click="$router.push('/notice-details/s5')">
                    <div class="align">
                        <p class="to">下一篇</p>
                        <p class="to-title">中再物贸云网依法进行主体登记，履行纳税义务</p>
                    </div>
                </div>
            </a-col>
        </a-row>
    </a-row>

    <a-row class="right">
        <a-col class="date">其他公告</a-col>
        <a-divider class="main"></a-divider>
        
        <a-row class="r-item" v-for="item in list" :key="item.title">
            <a-col class="to-title" @click="$router.push(item.router)">{{item.title}}</a-col>
            <a-col class="r-text">{{item.text}}</a-col>
            <a-col class="r-date"><a-icon type="clock-circle" /> 2021-12-15</a-col>
            <a-divider></a-divider>
        </a-row>
    </a-row>
  </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {title:'中再物贸云交易平台上线啦！',text:'针对再生资源行业税票乱象问题，中再物贸云通过多年的交易经验与相关部门合作...', router: '/notice-details/s1'},
                {title:'中再物贸云交易平台执行“实名认证”入驻',text:'为确保交易自然人的知悉权，避免身份被他人借用的情况，平台卖家入驻时需...',router: '/notice-details/s3'},
                {title:'增值税1%征收率即将结束',text:'小规模增值税1%征收率将于2021年12月31结束，2022年1月1日起按3%征收...',router: '/notice-details/s2'},
                {title:'中再物贸云网依法进行主体登记，履行纳税义务',text:'依据《中华人民共和国电子商务法》, 中再物贸云交易平台依法进行主体登记，履行纳税义务...',router: '/notice-details/s5'},
            ]
        }
    }
}
</script>

<style lang="less" scoped>
.left {
    @media screen and (max-width: 1440px){
        width: 60%;
    }
    display: inline-block;
    vertical-align: top;
    width: 960px;
    margin-right: 42px;
}
.right {
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1440px){
        width: calc(40% - 42px);
    }
    width: 352px;
    line-height: 24px;
}
.title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 16px;
}
.date {
    color: #AAAAAA;
    font-size: 16px;
}
.content {
    p{
        line-height: 26px;
        text-indent: 2em;
    }
    li {
        margin-left: 3.5em;
        line-height: 26px;
        list-style: initial;
    }
}
.to-title {
    cursor: pointer;
    font-weight: 700;
    color: #666666;
    font-size: 18px;
    transition: all .1s;
    &:hover {
        color: rgba(234, 164, 0, 1);
    }
}
.next {
    margin: 62px 0;
    cursor: pointer;
    border-left: 3px solid rgba(215, 215, 215, 1);
    background-color: rgba(242, 242, 242, 1);
    height: 100px;
    position: relative;
    transition: all .1s;
    .align {
        margin-left: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        .to {
            color: rgb(170, 170, 170);
            line-height: 28px;
        }
    }
    &:hover {
        background-color: #0378e6;
        .to,.to-title {
            color: #fff !important;
        }
    }
}
.r-item {
    .r-date {
        color: #AAAAAA;
        font-size: 14px;
    }
    .r-text {
        font-size: 14px;
        color: #AAAAAA;
        margin-top: 6px;
        line-height: 24px;
    }
}
/deep/ .ant-divider-horizontal{
    margin: 12px 0;
}
.main {
    background: #d7d7d7;
    margin: 12px 0 16px 0;
}
</style>